import styled from 'styled-components';

import { SubTitle } from '../Fonts';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledField = styled.input<{ isError?: boolean }>`
  flex: 1 1;
  border: solid 1px #ccc;

  :focus {
    outline: none;
  }

  font-family: 'PT Sans';

  width: 100%;
`;

export const StyledErrorMessage = styled(SubTitle)`
  color: ${(props) => props.theme.colors.text.danger};
  margin-top: 8px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
